export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principale"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Portfolio"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me joindre"])}
  },
  "main": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut, je suis Mathieu"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développeur frontend & Amoureux du Css "])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum Vitae"])},
    "meet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enchanté de vous rencontrer"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MES PROJETS"])},
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generateur de quote"])},
    "quotetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici un petit projet utilisant Javascript et API où vous pourrez avoir une citation aléatoire si vous en ressentez le besoin..."])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire à plusieurs étapes"])},
    "multitext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour celui-ci, j'ai utilisé principalement Javascript et Css pour créer un formulaire à plusieurs étapes où vous pouvez ajouter votre abonnement."])},
    "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["    Tips Calcultator    "])},
    "tipstext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["    J'essaie toujours de trouver le juste prix à offrir à quelqu'un, alors si vous avez besoin de calculer et de partager l'addition, voici un calculateur d'astuces JS."])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revue"])},
    "reviewtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis toujours en train d'entraîner mes compétences en Js avec ce commentaire de soumission juste pour donner un grand cinq à quelqu'un."])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balayage"])},
    "scantext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psst.... si vous avez besoin de plus d'informations, vous pouvez utiliser votre téléphone et scanner ici, un projet assez simple mais ne manquez pas l'occasion de vous connecter."])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
    "testtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un site web frontal utilisant HTML/CSS/JS, c'est une page simple, c'était ma première tentative de recréer une page d'atterrissage complète"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de détails à venir"])},
    "moretext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai travaillé sur un gros projet pendant mon séjour dans ma précédente agence, c'était génial, j'ai hâte de continuer "])},
    "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus "])},
    "neat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voilà"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-moi"])},
    "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022. Vue.js & CSS Portfolio. Développé par Mathieu Hollande"])}
  }
}