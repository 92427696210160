export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Portfolio"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "main": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, I'm Mathieu"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend developer & Css lover"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
    "meet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nice to meet you"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY PROJECTS"])},
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote Generator Api"])},
    "quotetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here is a small project using Javascript and  API where you can have a random quote if you feel in need ..."])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multistep Form"])},
    "multitext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this one I use mainly Javascript and Css to create a multistep form where you can add your plan"])},
    "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["    Tips Calcultator    "])},
    "tipstext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another one, I always try to find the just price to give to someone so in case you need to calculate and split the bill here is a great JS tips calculator"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "reviewtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Still training my Js skills with this submit review just to give someone a big 5 "])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan me"])},
    "scantext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psst....if you need more informations, you can use your phone and scan here, pretty simple project but don't miss the chance to connect "])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend Website"])},
    "testtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an frontend website using HTML/CSS/JS, it's a simple page,it was my first attempt to recreate an entire landing page "])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More to come"])},
    "moretext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I worked on some big project during my time at my previous agency it was great can't wait to keep going "])},
    "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" More Info"])},
    "neat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's pretty neat"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow me"])},
    "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022. Vue.js & CSS Portfolio. Developed by Mathieu.H"])}
  }
}